* {
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  background-color: ghostwhite;
}

.pokedex {
  margin: 0;
  background-color: ghostwhite;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.pokedex__search {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.pokedex__searchbar {
  font-size: 2rem;
  padding: 1rem;
  border: 2px solid black;
  border-radius: 3px;
}

.pokedex__submit {
  padding: 1.4rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  font-size: 1.5rem;
  background-color: rgb(14, 158, 206);
  color: ghostwhite;
  font-weight: 600;
  transition: background-color 0.3s ease;
  border-radius: 3px;
  border: none;
}

.pokedex__submit:hover {
  cursor: pointer;
  background-color: rgb(17, 172, 224);
}

.pokedex__result {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.pokedex__title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.pokedex__name {
  font-size: 3rem;
  color: black;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
  text-transform: capitalize;
}

.pokedex__number {
  font-size: 3rem;
  color: rgb(92, 92, 92);
  font-family: 'Fira Sans', sans-serif;
}

.pokedex__wrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.pokedex__left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: end;
}

.pokedex__right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Fira Sans', sans-serif;
  font-size: 1.75rem;
  gap: 1.5rem;
}


.pokedex__image {
  width: 60%;
  background-color: #eee;
  border-radius: 3%;
}

.pokedex__stats {
  background-color: gray;
  border-radius: 3%;
  width: 60%;
  height: 100%;
}

.pokedex__description {
  max-width: 60%;
}

.pokedex__versions {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.75rem;
}

.pokedex__versionsTitle {
  font-weight: 500;
}

.pokedex__male {
  color: darkcyan;
  font-size: 2rem;
}

.pokedex__female {
  color: palevioletred;
  font-size: 2rem;
}

.pokedex__gender {
  border: solid 0.3rem rgb(255, 63, 63);
  border-radius: 50%;
  padding: 0.5rem;
}

.pokedex__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(14, 158, 206);
  border-radius: 3%;
  padding: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 60%;
}

.pokedex__info--left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.pokedex__info--right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-right: 4rem;
}

.pokedex__infotext {
  gap: 0.2rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.pokedex__subtitle {
  color: white;
  font-size: 1.45rem;
}

.pokedex__infographic {
  color: black;
  font-size: 1.75rem;
  text-transform: capitalize;
}

.pokedex__infogenders {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.pokedex__infogenders--male {
  font-size: 1.75rem;
}

.pokedex__infogenders--female {
  font-size: 1.75rem;
}

.pokedex__labeltitle {
  font-weight: 500;
}

.pokedex__labels {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 70%;
}

.pokedex__types {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.pokedex__weaknesses {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.pokedex__label {
  font-size: 1.5rem;
  background-color: rgb(209, 209, 209);
  padding: 0.5rem;
  width: 12.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5%;
  color: white;
  text-transform: capitalize;
}

.pokedex__label--normal {
  background-color: #A8A878;
}

.pokedex__label--fighting {
  background-color: #C03028;
}

.pokedex__label--flying {
  background-color: #A890F0;
}

.pokedex__label--poison {
  background-color: #A040A0;
}

.pokedex__label--ground {
  background-color: #E0C068;
}

.pokedex__label--rock {
  background-color: #B8A038;
}

.pokedex__label--bug {
  background-color: #A8B820;
}

.pokedex__label--ghost {
  background-color: #705898;
}

.pokedex__label--steel {
  background-color: #B8B8D0;
}

.pokedex__label--fire {
  background-color: #F08030;
}

.pokedex__label--water {
  background-color: #6890F0;
}

.pokedex__label--grass {
  background-color: #78C850;
}

.pokedex__label--electric {
  background-color: #F8D030;
}

.pokedex__label--psychic {
  background-color: #F85888;
}

.pokedex__label--ice {
  background-color: #98D8D8;
}

.pokedex__label--dragon {
  background-color: #7038F8;
}

.pokedex__label--dark {
  background-color: #705848;
}

.pokedex__label--fairy {
  background-color: #EE99AC;
}

.pokedex__label--unknown {
  background-color: #68A090;
}

.pokedex__label--shadow {
  background-color: #5A5A5A;
}

@media screen and (max-width: 800px) {
  .pokedex__wrapper {
    flex-direction: column;
  }

  .pokedex__left {
    align-items: center;
  }

  .pokedex__stats {
    display: none;
  }

  .pokedex__right {
    align-items: center;
    gap: 2.5rem;
  }

  .pokedex__labels {
    max-width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .pokedex__description {
    max-width: 75%;
  }

  .pokedex__info {
    width: 80%;
  }

  .pokedex__labels {
    max-width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .pokedex__info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

@media screen and (max-width: 250px) {
  .pokedex__title {
    display: flex;
    flex-direction: column;
  }

  .pokedex__versions {
    display: flex;
    flex-direction: column;
  }
}